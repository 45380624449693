import React from "react"
import { css } from "@emotion/react"
import { md } from "../../styles/mediaQuery"
import { Alert, Col, Row } from "antd"
import { CourseCard } from "../CourseCard/CourseCard"
import { getTechnology } from "../../lib/prismic/mapData"
import { PrismicDocument } from "@prismicio/types"
import { Theme } from "../../styles/theme"
import { chunk } from "lodash"
import { TheCarouselBatchItem } from "./TheCarouselBatchItem"
import { ColProps } from "antd/lib/col"
import { getCourse, isContentfulEshopProduct } from "../../lib/prismic/getCourse"
import { ProductsColorsItem } from "../../lib/contentful/product/getAllProducts"
import { PrismicCourseOrContentfulProduct } from "../../lib/contentful/PrismicCourseOrContentfulProduct"
import { ProductCard } from "../CourseCard/ProductCard"
import { Entry } from "contentful"
import { TypeProductEntitySkeleton } from "../../lib/contentful/ContentfulVzdelanibudoucnostiTypes"

export const CoursesList = ({
  courses = [],
  productsColors,
  simpleLayout = false,
  className,
  theme,
  informationOnly,
  whenOnHeader,
  withSubCourse,
  carouselWidth,
  colProps = { xs: 12, sm: 6, md: 6, lg: 6, xl: 6 },
  dateAtHeader = false
}: Props) => {
  const items = (courses ?? []).map((d) => {
    if (isContentfulEshopProduct(d)) {
      const newContentfulProduct = d as Entry<TypeProductEntitySkeleton, undefined, string>
      const productColors = (productsColors ?? []).find((c) => c.id === newContentfulProduct.sys.id)

      return (
        <ProductCard
          key={newContentfulProduct?.["sys"].id}
          product={newContentfulProduct}
          productColors={productColors}
          simpleLayout={simpleLayout}
          theme={theme}
          informationOnly={informationOnly}
          whenOnHeader={whenOnHeader}
          withSubCourse={withSubCourse}
          dateAtHeader={dateAtHeader}
        />
      )
    }

    const oldPrismicCourse = d as PrismicDocument<any>

    return (
      <CourseCard
        key={d?.["id"]}
        course={getCourse(oldPrismicCourse)}
        technology={getTechnology(oldPrismicCourse)}
        simpleLayout={simpleLayout}
        theme={theme}
        informationOnly={informationOnly}
        whenOnHeader={whenOnHeader}
        withSubCourse={withSubCourse}
        dateAtHeader={dateAtHeader}
      />
    )
  })

  if (!items || items.length === 0) {
    return (
      <Alert
        message="V této sekci zatím nemáme vypsané žádné kurzy."
        description="Momentálně nejsou dostupné žádné kurzy k registraci. Pracujeme na nové nabídce, kterou brzy zveřejníme. Pokud chcete být mezi prvními, kdo se o nových kurzech dozví, přihlaste se k odběru novinek – formulář najdete níže na této stránce, nebo na hlavní stránce."
        type="warning"
      />
    )
  }

  return (
    <>
      {items.length >= 2 &&
        !carouselWidth &&
        chunk(items, 6).map((chunkItems, index) => (
          <TheCarouselBatchItem key={`chunkItems-${index}`} carouselItems={chunkItems} simpleLayout={simpleLayout} className={className} />
        ))}

      <Row
        gutter={[
          { xs: 14, sm: 14, md: 14, lg: 14, xl: 30 },
          { xs: 14, sm: 14, md: 14, lg: 14, xl: 30 }
        ]}
        css={css`
          ${items.length >= 2 && !carouselWidth
            ? `
              ${md} {
                display: none;
              }            
          `
            : ""}
        `}
        className={className}>
        {items.map((item, i) => (
          <Col key={`Col-${i}`} {...colProps}>
            {item}
          </Col>
        ))}
      </Row>
    </>
  )
}

interface Props {
  courses?: PrismicCourseOrContentfulProduct[]
  productsColors?: ProductsColorsItem[]
  simpleLayout?: boolean
  className?: string
  theme?: Theme
  informationOnly?: boolean
  whenOnHeader?: boolean
  withSubCourse?: boolean
  carouselWidth?: boolean
  colProps?: ColProps
  dateAtHeader?: boolean
}
