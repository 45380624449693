import React from "react"
import { Button, Typography } from "antd"
import Link from "next/link"
import { css } from "@emotion/react"
import { sm } from "../../styles/mediaQuery"
import Image from "next/legacy/image"
import { Theme } from "../../styles/theme"
import { BottomSpace, CourseCardWrapper, ellipsis, paragraphStyle, TopSpace, TopSpaceText } from "./commonForCards"
import { Asset, Entry } from "contentful"
import { TypeProductEntitySkeleton } from "../../lib/contentful/ContentfulVzdelanibudoucnostiTypes"
import { getProductSlug } from "../../lib/contentful/product/getProductSlug"
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"
import { ProductsColorsItem } from "../../lib/contentful/product/getAllProducts"
import { ShoppingCartOutlined } from "@ant-design/icons"
import { useCart } from "../Cart/useCart"
import { useRouter } from "next/router"
import { getTechnology } from "../../lib/prismic/mapData"
import styled from "@emotion/styled"

export const ProductCard = ({
  product,
  simpleLayout = false,
  theme,
  informationOnly = false,
  whenOnHeader = false,
  dateAtHeader = false,
  productColors
}: ProductCardType) => {
  const router = useRouter()
  const { addToCart } = useCart()

  const {
    fields: { title, image, description, price, subType }
  } = product

  const technology = getTechnology(product)
  const isShoptable = subType === "product"

  const imageAsset = image ? (image as Asset<undefined, string>) : undefined
  const descriptionAsText = documentToPlainTextString(description)
  const url = isShoptable ? `/eshop/produkt/${getProductSlug(product)}` : `/registrace/${getProductSlug(product)}`

  const textColor = "#fff"
  const color = productColors?.mainColor ? productColors?.mainColor : technology ? technology.color : "rgba(0,0,0,0.85)"

  return (
    <>
      <CourseCardWrapper theme={theme}>
        <Link href={url} title={`Přejít na ${title}`}>
          <TopSpace simpleLayout={simpleLayout} height={whenOnHeader ? "175px" : undefined}>
            <TopSpaceText color={color} bgOpacity={imageAsset ? 0.75 : 1}>
              {!whenOnHeader && (
                <Typography.Text
                  type="secondary"
                  style={{ color: textColor, fontSize: 11, textTransform: "uppercase" }}
                  css={css`
                    ${sm} {
                      display: none;
                    }
                  `}
                />
              )}

              <Typography.Title
                level={3}
                style={{
                  color: textColor,
                  fontSize: whenOnHeader ? 14 : 15,
                  lineHeight: whenOnHeader ? 1.2 : "normal",
                  marginBottom: 0
                }}>
                {title}
              </Typography.Title>
            </TopSpaceText>

            <div
              css={css`
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              `}>
              {imageAsset && <Image alt={imageAsset.fields.title} src={`https:${imageAsset.fields.file.url}`} layout="fill" objectFit="cover" />}
            </div>
          </TopSpace>
        </Link>

        {!simpleLayout && (
          <BottomSpace theme={theme}>
            <Typography.Paragraph style={paragraphStyle} ellipsis={ellipsis}>
              {descriptionAsText}
            </Typography.Paragraph>

            <FlexEnd
              css={css`
                ${isShoptable ? `justify-content: space-between;` : `justify-content: end;`}
                ${sm} {
                  flex-direction: column;
                  gap: 8px;
                  align-items: flex-start;
                }
              `}>
              {isShoptable && (
                <Button
                  onClick={() => {
                    addToCart({ id: product.sys.id, name: title, price, quantity: 1, url: router.asPath })
                    router.push(`/kosik`)
                  }}
                  style={{
                    backgroundColor: color,
                    borderColor: color,
                    color: textColor
                  }}
                  icon={<ShoppingCartOutlined />}
                />
              )}

              <Button
                type="primary"
                style={{
                  backgroundColor: color,
                  borderColor: color,
                  color: textColor
                }}>
                <Link href={url}>Více informací</Link>
              </Button>
            </FlexEnd>
          </BottomSpace>
        )}
      </CourseCardWrapper>
    </>
  )
}

interface ProductCardType {
  product: Entry<TypeProductEntitySkeleton, undefined, string>
  productColors: ProductsColorsItem
  simpleLayout?: boolean
  theme?: Theme
  informationOnly?: boolean
  whenOnHeader?: boolean
  withSubCourse?: boolean
  dateAtHeader?: boolean
}

const FlexEnd = styled.span`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 9px;
`
