import React from "react"
import { Button, Rate, Space, Typography } from "antd"
import Link from "next/link"
import { css } from "@emotion/react"
import { md, sm } from "../../styles/mediaQuery"
import { H4 } from "../blog/common"
import Image from "next/legacy/image"
import { desaturate } from "polished"
import { Theme } from "../../styles/theme"
import { theme as themeColorPalette } from "../../styles/styles"
import { SubCourseCard } from "./SubCourseCard"
import {
  BottomSpace,
  CourseCardType,
  CourseCardWrapper,
  ellipsis,
  Flex,
  FlexEnd,
  getTopSpaceTextColorTheme,
  grayScaleImageStyle,
  h4Style,
  paragraphStyle,
  removePattern,
  TopSpace,
  TopSpaceText
} from "./commonForCards"

export const CourseCard = ({
  course,
  technology,
  simpleLayout = false,
  theme,
  informationOnly = false,
  whenOnHeader = false,
  withSubCourse = true,
  dateAtHeader = false
}: CourseCardType) => {
  const textColor = theme === Theme.dark ? themeColorPalette.colors.dark.textWhite : "#fff"
  const color = getTopSpaceTextColorTheme(technology, !!course.isOpen, theme)

  return (
    <>
      <CourseCardWrapper theme={theme}>
        <Link href={course.registerUrl} title={`Přejít na ${removePattern(course.name)}`}>
          <TopSpace simpleLayout={simpleLayout} height={whenOnHeader ? "175px" : undefined}>
            <TopSpaceText color={color} bgOpacity={course.image ? 0.75 : 1}>
              {!whenOnHeader && (
                <Typography.Text
                  type="secondary"
                  style={{ color: textColor, fontSize: 11, textTransform: "uppercase" }}
                  css={css`
                    ${sm} {
                      display: none;
                    }
                  `}>
                  {course.descriptionType}
                </Typography.Text>
              )}

              {!whenOnHeader && dateAtHeader ? (
                <Flex>
                  <Typography.Title
                    level={3}
                    style={{ color: textColor, fontSize: whenOnHeader ? 14 : 15, lineHeight: whenOnHeader ? 1.2 : "normal", marginBottom: 10 }}>
                    {removePattern(course.name)}
                  </Typography.Title>

                  {course.firstCourseDay && (
                    <Typography.Title level={5} style={{ color: textColor, marginBottom: 0, marginTop: 0 }}>
                      Od {course.firstCourseDay.format("ll")}
                    </Typography.Title>
                  )}
                </Flex>
              ) : (
                <>
                  <Typography.Title
                    level={3}
                    style={{ color: textColor, fontSize: whenOnHeader ? 14 : 15, lineHeight: whenOnHeader ? 1.2 : "normal", marginBottom: 0 }}>
                    {removePattern(course.name)}
                  </Typography.Title>

                  {!whenOnHeader && dateAtHeader && course.firstCourseDay && (
                    <Typography.Title level={5} style={{ color: textColor, marginBottom: 0, marginTop: 0 }}>
                      Od {course.firstCourseDay.format("ll")}
                    </Typography.Title>
                  )}

                  {whenOnHeader && (
                    <Typography.Title level={5} style={{ color: textColor, fontSize: 11, lineHeight: 1.2, marginBottom: 0, marginTop: 0 }}>
                      {course.when}
                    </Typography.Title>
                  )}
                </>
              )}
            </TopSpaceText>

            <div
              css={css`
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                ${course.isOpen ? "" : grayScaleImageStyle}
              `}>
              {course.image && course.image.url && (
                <Image alt={course.image.alt || "course-image-url"} src={course.image.url} layout="fill" objectFit="cover" />
              )}
            </div>
          </TopSpace>
        </Link>

        {!simpleLayout && (
          <BottomSpace theme={theme}>
            {informationOnly ? (
              <Typography.Paragraph style={paragraphStyle} ellipsis={course.subCourse ? { ...ellipsis, rows: 4 } : ellipsis}>
                {course.excerptAsText}
              </Typography.Paragraph>
            ) : (
              <>
                {course.rate && course.rate > 0 ? (
                  <Rate
                    disabled
                    defaultValue={course.rate}
                    style={{ fontSize: 14 }}
                    css={css`
                      position: absolute;
                      right: 0;
                      top: 0;
                      margin: 10px 10px 0 0;

                      ${md} {
                        display: none;
                      }
                    `}
                  />
                ) : null}

                <Space direction="vertical">
                  {course.where && (
                    <div>
                      <H4 level={4} style={h4Style}>
                        Kde?
                      </H4>
                      <Typography.Paragraph style={paragraphStyle} ellipsis={ellipsis}>
                        {course.where}
                      </Typography.Paragraph>
                    </div>
                  )}

                  {course.when && (
                    <div>
                      <H4 level={4} style={h4Style}>
                        Kdy?
                      </H4>
                      <Typography.Paragraph style={paragraphStyle} ellipsis={ellipsis}>
                        {course.when}
                      </Typography.Paragraph>
                    </div>
                  )}

                  <div>
                    <H4 level={4} style={h4Style}>
                      Informace
                    </H4>
                    <Typography.Paragraph style={paragraphStyle} ellipsis={ellipsis}>
                      {course.excerptAsText}
                    </Typography.Paragraph>
                  </div>
                </Space>
              </>
            )}

            <FlexEnd>
              {course.isOpen ? (
                <Button
                  type="primary"
                  {...(theme === Theme.dark && {
                    style: {
                      backgroundColor: desaturate(0.3, themeColorPalette.colors["primary-color"]),
                      borderColor: desaturate(0.4, themeColorPalette.colors["primary-color"])
                    }
                  })}>
                  <Link href={course.registerUrl}>Více informací</Link>
                </Button>
              ) : (
                <Button type="primary">
                  <Link href={course.registerUrl}>Více informací</Link>
                </Button>
              )}
            </FlexEnd>
          </BottomSpace>
        )}

        {withSubCourse && course.subCourse && <SubCourseCard subCourse={course.subCourse} />}
      </CourseCardWrapper>
    </>
  )
}
